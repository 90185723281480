<template>
  <div class="form-group">
    <div class="position-relative">
      <!-- Label -->
      <div class="row">
        <div class="col">
          <label
            v-bind:for="componentId"
            class="custom-label"
            v-bind:class="{ 'text-danger': dangerStatus }"
            >{{ label }}</label
          >
        </div>
        <div class="col-auto" v-if="dangerStatus">
          <small class="text-danger"
            ><i>{{ $t("thisSlugIsInUse") }}</i></small
          >
        </div>
      </div>
      <!-- Label -->

      <div class="input-group">
        <!-- URL -->
        <div class="input-group-prepend" v-if="siteUrl">
          <span class="input-group-text">{{ siteUrl }}</span>
        </div>
        <!-- URL -->
        <!-- Input -->
        <input
          type="text"
          class="form-control rounded-right"
          v-bind:class="{
            'border-danger': dangerStatus,
            'border-right-0 rounded-0': data.slug,
          }"
          v-bind:placeholder="getPlaceholder"
          v-bind:id="componentId"
          v-bind:value="data.slug"
          v-on:input="onInput"
          ref="inputData"
        />
        <!-- Input -->

        <div class="input-group-append">
          <!-- Search Container -->
          <div
            class="input-group-text bg-white border-left-0"
            v-if="search && query"
          >
            <i
              class="fas fa-spinner fa-spin"
              v-if="!searchReady && query && query.length >= searchLength"
            ></i>
            <i
              class="fas fa-check-circle text-success"
              v-if="searchReady && isUnique"
            ></i>
            <i
              class="fas fa-times-circle text-danger"
              v-if="searchReady && !isUnique"
            ></i>
          </div>
          <div class="input-group-text bg-white border-left-0" v-if="data.slug">
            <a v-bind:href="fullUrl" target="_blank"
              ><i class="fas fa-external-link-alt"></i
            ></a>
          </div>
          <!-- Search Container -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "WeSlugCheck",
  data() {
    return {
      searchReady: false,
      isUnique: false,
      query: "",
      data: {
        slug: this.slug,
      },
    };
  },
  props: {
    id: {
      default: null,
    },
    label: {
      default: "Slug",
    },
    urlFragment: {
      default: "",
    },
    value: {
      default: null, // Product Name etc.
    },
    slug: {
      default: "", // Slug from server
    },
    search: {
      default: false,
    },
    type: {
      default: "product", // Model
    },
    placeholder: {
      default: "",
    },
    searchLength: {
      default: 3,
    },
    searchDelay: {
      default: 1000,
    },
  },
  methods: {
    ...mapActions("shared", ["searchSlug"]),
    onInput(e) {
      const inputValue = e && e.target ? e.target.value : e;
      this.data.slug = inputValue;
      this.query = inputValue;

      if (this.search) {
        this.doSearch(inputValue, this.searchDelay);
      }
    },
    doSearch(text, timeout) {
      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        this.setSearching();
        if (text.trim().length >= this.searchLength) {
          this.emitSearch(text, localTimeout);
        }
      }
    },
    emitSearch(text, timeout) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        clearTimeout(this.timer);

        const searchData = {
          slug: text,
          type: this.type,
        };
        if (this.id) {
          searchData.id = this.id;
        }

        this.searchSlug({
          form: searchData,
          onSuccess: (result) => {
            this.searchReady = true;
            if (result) {
              this.isUnique = !result.data.status;
              this.$emit("slugify", this.data.slug);
            } else {
              this.isUnique = false;
            }
          },
        });
      }, timeout);
    },
    setSearching() {
      this.searchReady = false;
      this.isUnique = false;
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    componentId() {
      return "we-slug-checker-" + helper.getRandomInteger(1, 9999);
    },
    getPlaceholder() {
      return this.placeholder || this.label;
    },
    dangerStatus() {
      return this.searchReady && this.query && !this.isUnique;
    },
    siteUrl() {
      let configUrl = this.config["site.url"];
      let prefixSetting = this.config["site.slug_prefix"];
      let result = "";

      if (configUrl) {
        let lastChar = configUrl.substr(-1);
        if (lastChar !== "/") {
          configUrl += "/";
        }
        if (this.urlFragment && (prefixSetting == "1" || prefixSetting == 1)) {
          return configUrl + this.urlFragment + "/";
        }
        return configUrl;
      }

      return result;
    },
    fullUrl() {
      return this.siteUrl + this.data.slug;
    },
  },
  watch: {
    value: function (newVal) {
      let slugData = helper.slugify(newVal);
      this.data.slug = slugData;
      this.onInput(slugData);
    },
  },
};
</script>
<style lang="scss" scoped>
.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus + div > .input-group-text {
  border-color: #80bdff;
  outline: 0;
}
.form-control.border-danger + div > .input-group-text {
  border-color: red;
  outline: 0;
}
</style>
